import React from 'react';
import './App.css';

function App() {
  
  return (
    <main>
      <h1>Emilie Smit</h1>
      <h2>04-04-2021 - 23:24 - 2700 gram</h2>
    </main>
  );
}

export default App;
